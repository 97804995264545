import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { APP_CONST } from "../config/const";
const PublicRoutes = ({ isAuth, redirectPath = "/home", children }) => {
  const { appDetails } = useSelector((state) => state.app);
  if (isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};
export default PublicRoutes;
