import axios from "axios";
import store from "../store/store";
import { logoutUser } from "../redux/auth/actions";
import { APP_CONST } from "../config/const";

const userToken = localStorage.getItem("token");
export const base_url = process.env.REACT_APP_API_HOST;
let withoutToken = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export const getLocalToken = () => localStorage.getItem("token");

if (getLocalToken() !== null) {
  withoutToken = { ...withoutToken, Authorization: getLocalToken() };
}

if (process.env.NODE_ENV === APP_CONST.ENVIRONMENTS.DEVELOPMENT) {
  withoutToken = { ...withoutToken, refererLocal: window.location.origin };
}

export const axiosService = axios.create({
  baseURL: base_url,
  headers: withoutToken,
});
axiosService.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status === 401 &&
      error.response.data.message === "Unauthorized action."
    ) {
      if (getLocalToken()) {
        store.dispatch(logoutUser());
      }
    }
    return Promise.reject(error);
  }
);
/**
 *
 * @param token
 */
export const setUserToken = (token) => {
  if (token) {
    axiosService.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axiosService.defaults.headers.common["Authorization"] = token;
  } else {
    axiosService.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }
};

export const removeUserToken = () => {
  delete axiosService.defaults.headers.common["Authorization"];
};
export const AxiosApiHelper = async ({ method, url, headers }) => {
  return axiosService
    .request({
      method: method || "get",
      url,
      headers,
    })
    .then((res) => {
      return res?.data.data;
    })
    .catch((error) => {
      console.log("Errorrrr", error);
      return error.response.data;
    });
};
